html {
    font-size: 100%;
}
body, html {
    height: 100%;
}

body {
    color: #243238;
    font-size: 1rem;
    min-width: 320px;
    width: 100%;
    font-family: @fontFamilyOpenSans;
}
p, blockquote, a {
	font-size: 1rem;
	font-family: @fontFamilyOpenSans;
}
h1 { 
	font-size: 1.6rem; 
	font-weight: bold; 
	font-family: @fontFamily;
}
h2 { 
	font-size: 1.4rem; 
	font-weight: bold; 
	font-family: @fontFamily;
}
h3 { 
	font-size: 1.2rem; 
	font-family: @fontFamily;
}
h4 { 
	font-size: 1rem; 
	font-family: @fontFamily;
}
h5 { 
	font-size: .9rem; 
	font-family: @fontFamily;
}

main.main {
	box-shadow: inset 0px 200px 50px -100px rgba(255, 255, 255, 0.95);
	margin-bottom: 0 !important;
    >header.main-header {
		box-shadow: inset 0px 0px 100px -10px #b7b7b7;
        >section.header-section {
        	>.section-header {
	        	.container {
	        		.row {
	        			>div {
	        				>a.logo-company {
	        					>img {
								    height: 100px;
								    padding: 10px;
	        					}
	        				}
	        				>h2 {
								color: #000000;
								font-size: 32px;
								line-height: 70px;
	        				}
	        			}
	        		}
	        	}
        	}
        	>.section-middle {
	            .navbar {
					background: @colorPrimary;
					margin: 0;
					border: none;
	                ul.navbar-nav {
	                    >li.nav-item {
	                    	.transition() ;
	                        >a.nav-link {
							    margin: 5px;
							    border-radius: 5px;
							    font-family: @fontFamily;
							    font-size: 1.2rem;
	                            .transition() ;
	                        }
                            >ul.dropdown-menu {
								border-radius: 5px;
								left: 5px;
								top: 53px;
                            	>li.dropdown-submenu {
                            		>a.nav-link {
                            			position: relative;
                            			z-index: 1;
	                            		font-family: @fontFamily;
	                            		font-size: 1rem;
	                            		color: #fff;
	                            		padding: .5rem;
	                            		.transition();
	                            		>span.icon-dropdown-toggle {
											margin-right: -20px;
	                            			float: right;	
										    background-color: #fff;
										    border: 1px solid;
										    border-radius: 0 5px 5px 0;
										    opacity: 0.2;
										    .transition();
	                            		}
		                            	&:hover >span.icon-dropdown-toggle {
		                            		opacity: 1;
		                            	}
                            		}
                            		div.dropdown-menu, ul.dropdown-menu {
                            			position: absolute;
                            			z-index: 0;
                            			left: 100%;
                            			top: -11px;
                            			margin-left: 5px;
                            			padding: 12px 0;
                        				>a.dropdown-item {
                        					padding: .5rem;
                        				}
                            			>.dropdown-submenu {
                            				>a.nav-link {
                            					padding: .5rem;
                            				}
                            			}
                            		}
                            	} 
                            	>a.dropdown-item {
                            		font-family: @fontFamily;
                            		font-size: 1rem;
                            		color: #fff;
                            		padding: .5rem;
                            		.transition();
                            	}
                            }
                            &.color-blue {
                            	>a.nav-link {
	                            	background-color: @colorBlue;
		                           	color: #fff;
		                            &:hover,
		                            &:focus {
	                            		color: @colorBlue;
		                           		background-color: #fff;
		                            }
                            	}
                            	>ul.dropdown-menu {
                            		border: 1px solid @colorBlue !important;	
                            		background-color: @colorBlue !important;	
	                            	>li.dropdown-submenu {
			                            >a {
			                            	color: #ffffff !important;
			                            	&:hover,
			                            	&:focus {
					                            color: @colorBlue !important;
					                            background-color: #fff !important;
			                            	}
	                            		}
		                            	>ul.dropdown-menu {
			                            	border: 2px solid @colorBlue !important;	
			                            	background-color: #ffffff !important;	
			                            	a.dropdown-item {
			                            		color: #000 !important;
			                            		&:hover,
			                            		&:focus {
					                            	color: @colorBlue !important;
			                            		}
			                        		}
			                        		li.dropdown-submenu {
			                        			>a.nav-link {
				                            		color: #000 !important;
				                            		.transition();
				                            		>span.icon-dropdown-toggle {
													    margin-right: -20px;
													    background-color: #fff;
													    border: 1px solid;
													    border-radius: 0 5px 5px 0;
				                            			float: right;	
				                            			opacity: 0.2;
				                            			.transition();
				                            		}
				                            		&:hover {
				                            			color: @colorBlue !important;
				                            			>span.icon-dropdown-toggle {
				                            				opacity:  1;
				                            			}
				                            		}
				                        		}
			                        			div.dropdown-menu {
												    border: 2px solid @colorBlue;	
				                            		a {
				                            			color: #000; 	
				                            		}
				                        		}
			                        		}
		                            	}
		                            }
	                            	>a.dropdown-item:hover {
	                            		color: @colorBlue !important;
	                            		background-color: #ffffff;
	                            	}
                            	}
                            }
                            &.color-purple {
                            	>a.nav-link {
	                            	background-color: @colorPurple;
		                           	color: #fff;
		                            &:hover,
		                            &:focus {
	                            		color: @colorPurple;
		                           		background-color: #fff;
		                            }
                            	}
                            	>ul.dropdown-menu {
                            		border: 1px solid @colorPurple !important;	
                            		background-color: @colorPurple !important;	
	                            	>li.dropdown-submenu {
			                            >a {
			                            	color: #ffffff !important;
			                            	&:hover,
			                            	&:focus {
					                            color: @colorPurple !important;
					                            background-color: #fff !important;
			                            	}
	                            		}
		                            	>ul.dropdown-menu {
			                            	border: 2px solid @colorPurple !important;	
			                            	background-color: #ffffff !important;	
			                            	a.dropdown-item {
			                            		color: #000 !important;
			                            		&:hover,
			                            		&:focus {
					                            	color: @colorPurple !important;
			                            		}
			                        		}
			                        		li.dropdown-submenu {
			                        			>a.nav-link {
				                            		color: #000 !important;
				                            		.transition();
				                            		>span.icon-dropdown-toggle {
													    margin-right: -20px;
													    background-color: #fff;
													    border: 1px solid;
													    border-radius: 0 5px 5px 0;
				                            			float: right;	
				                            			opacity: 0.2;
				                            			.transition();
				                            		}
				                            		&:hover {
				                            			color: @colorPurple !important;
				                            			>span.icon-dropdown-toggle {
				                            				opacity:  1;
				                            			}
				                            		}
				                        		}
			                        			div.dropdown-menu {
												    border: 2px solid @colorPurple;	
				                            		a {
				                            			color: #000; 	
				                            		}
				                        		}
			                        		}
		                            	}
		                            }
	                            	>a.dropdown-item:hover {
	                            		color: @colorPurple !important;
	                            		background-color: #ffffff;
	                            	}
                            	}
                            }
                            &.color-green {
                            	>a.nav-link {
	                            	background-color: @colorGreen;
		                           	color: #fff;
		                            &:hover,
		                            &:focus {
	                            		color: @colorGreen;
		                           		background-color: #fff;
		                            }
                            	}
                            	>ul.dropdown-menu {
                            		border: 1px solid @colorGreen !important;	
                            		background-color: @colorGreen !important;	
	                            	>li.dropdown-submenu {
			                            >a {
			                            	color: #ffffff !important;
			                            	&:hover,
			                            	&:focus {
					                            color: @colorGreen !important;
					                            background-color: #fff !important;
			                            	}
	                            		}
		                            	>ul.dropdown-menu {
			                            	border: 2px solid @colorGreen !important;	
			                            	background-color: #ffffff !important;	
			                            	a.dropdown-item {
			                            		color: #000 !important;
			                            		&:hover,
			                            		&:focus {
					                            	color: @colorGreen !important;
			                            		}
			                        		}
			                        		li.dropdown-submenu {
			                        			>a.nav-link {
				                            		color: #000 !important;
				                            		.transition();
				                            		>span.icon-dropdown-toggle {
													    margin-right: -20px;
													    background-color: #fff;
													    border: 1px solid;
													    border-radius: 0 5px 5px 0;
				                            			float: right;	
				                            			opacity: 0.2;
				                            			.transition();
				                            		}
				                            		&:hover {
				                            			color: @colorGreen !important;
				                            			>span.icon-dropdown-toggle {
				                            				opacity:  1;
				                            			}
				                            		}
				                        		}
			                        			div.dropdown-menu {
												    border: 2px solid @colorGreen;	
				                            		a {
				                            			color: #000; 	
				                            		}
				                        		}
			                        		}
		                            	}
		                            }
	                            	>a.dropdown-item:hover {
	                            		color: @colorGreen !important;
	                            		background-color: #ffffff;
	                            	}
                            	}	
                            }
                            &.color-orange {                            	
                            	>a.nav-link {
	                            	background-color: @colorOrange;
		                           	color: #fff;
		                            &:hover,
		                            &:focus {
	                            		color: @colorOrange;
		                           		background-color: #fff;
		                            }
                            	}
                            	>ul.dropdown-menu {
                            		border: 1px solid @colorOrange !important;	
                            		background-color: @colorOrange !important;	
	                            	>li.dropdown-submenu {
			                            >a {
			                            	color: #ffffff !important;
			                            	&:hover,
			                            	&:focus {
					                            color: @colorOrange !important;
					                            background-color: #fff !important;
			                            	}
	                            		}
		                            	>ul.dropdown-menu {
			                            	border: 2px solid @colorOrange !important;	
			                            	background-color: #ffffff !important;	
			                            	a.dropdown-item {
			                            		color: #000 !important;
			                            		&:hover,
			                            		&:focus {
					                            	color: @colorOrange !important;
			                            		}
			                        		}
			                        		li.dropdown-submenu {
			                        			>a.nav-link {
				                            		color: #000 !important;
				                            		.transition();
				                            		>span.icon-dropdown-toggle {
													    margin-right: -20px;
													    background-color: #fff;
													    border: 1px solid;
													    border-radius: 0 5px 5px 0;
				                            			float: right;	
				                            			opacity: 0.2;
				                            			.transition();
				                            		}
				                            		&:hover {
				                            			color: @colorOrange !important;
				                            			>span.icon-dropdown-toggle {
				                            				opacity:  1;
				                            			}
				                            		}
				                        		}
			                        			div.dropdown-menu {
												    border: 2px solid @colorOrange;	
				                            		a {
				                            			color: #000; 	
				                            		}
				                        		}
			                        		}
		                            	}
		                            }
	                            	>a.dropdown-item:hover {
	                            		color: @colorOrange !important;
	                            		background-color: #ffffff;
	                            	}
                            	}		
                            }
                            &.color-lightblue {
                            	>a.nav-link {
	                            	background-color: @colorLightblue;
		                           	color: #fff;
		                            &:hover,
		                            &:focus {
	                            		color: @colorLightblue;
		                           		background-color: #fff;
		                            }
                            	}
                            	>ul.dropdown-menu {
                            		border: 1px solid @colorLightblue !important;	
                            		background-color: @colorLightblue !important;	
	                            	>li.dropdown-submenu {
			                            >a {
			                            	color: #ffffff !important;
			                            	&:hover,
			                            	&:focus {
					                            color: @colorLightblue !important;
					                            background-color: #fff !important;
			                            	}
	                            		}
		                            	>ul.dropdown-menu {
			                            	border: 2px solid @colorLightblue !important;	
			                            	background-color: #ffffff !important;	
			                            	a.dropdown-item {
			                            		color: #000 !important;
			                            		&:hover,
			                            		&:focus {
					                            	color: @colorLightblue !important;
			                            		}
			                        		}
			                        		li.dropdown-submenu {
			                        			>a.nav-link {
				                            		color: #000 !important;
				                            		.transition();
				                            		>span.icon-dropdown-toggle {
													    margin-right: -20px;
													    background-color: #fff;
													    border: 1px solid;
													    border-radius: 0 5px 5px 0;
				                            			float: right;	
				                            			opacity: 0.2;
				                            			.transition();
				                            		}
				                            		&:hover {
				                            			color: @colorLightblue !important;
				                            			>span.icon-dropdown-toggle {
				                            				opacity:  1;
				                            			}
				                            		}
				                        		}
			                        			div.dropdown-menu {
												    border: 2px solid @colorLightblue;	
				                            		a {
				                            			color: #000; 	
				                            		}
				                        		}
			                        		}
		                            	}
		                            }
	                            	>a.dropdown-item:hover {
	                            		color: @colorLightblue !important;
	                            		background-color: #ffffff;
	                            	}
                            	}
                            }
                            &.color-violet {
                            	>a.nav-link {
	                            	background-color: @colorViolet;
		                           	color: #fff;
		                            &:hover,
		                            &:focus {
	                            		color: @colorViolet;
		                           		background-color: #fff;
		                            }
                            	}
                            	>ul.dropdown-menu {
                            		border: 1px solid @colorViolet !important;	
                            		background-color: @colorViolet !important;	
	                            	>li.dropdown-submenu {
			                            >a {
			                            	color: #ffffff !important;
			                            	&:hover,
			                            	&:focus {
					                            color: @colorViolet !important;
					                            background-color: #fff !important;
			                            	}
	                            		}
		                            	>ul.dropdown-menu {
			                            	border: 2px solid @colorViolet !important;	
			                            	background-color: #ffffff !important;	
			                            	a.dropdown-item {
			                            		color: #000 !important;
			                            		&:hover,
			                            		&:focus {
					                            	color: @colorViolet !important;
			                            		}
			                        		}
			                        		li.dropdown-submenu {
			                        			>a.nav-link {
				                            		color: #000 !important;
				                            		.transition();
				                            		>span.icon-dropdown-toggle {
													    margin-right: -20px;
													    background-color: #fff;
													    border: 1px solid;
													    border-radius: 0 5px 5px 0;
				                            			float: right;	
				                            			opacity: 0.2;
				                            			.transition();
				                            		}
				                            		&:hover {
				                            			color: @colorViolet !important;
				                            			>span.icon-dropdown-toggle {
				                            				opacity:  1;
				                            			}
				                            		}
				                        		}
			                        			div.dropdown-menu {
												    border: 2px solid @colorViolet;	
				                            		a {
				                            			color: #000; 	
				                            		}
				                        		}
			                        		}
		                            	}
		                            }
	                            	>a.dropdown-item:hover {
	                            		color: @colorViolet !important;
	                            		background-color: #ffffff;
	                            	}
                            	}		
                            }
                            &.color-gray {
                            	>a.nav-link {
	                            	background-color: @colorGray;
		                           	color: #fff;
		                            &:hover,
		                            &:focus {
	                            		color: @colorGray;
		                           		background-color: #fff;
		                            }
                            	}
                            	>ul.dropdown-menu {
                            		border: 1px solid @colorGray !important;	
                            		background-color: @colorGray !important;	
	                            	>li.dropdown-submenu {
			                            >a {
			                            	color: #ffffff !important;
			                            	&:hover,
			                            	&:focus {
					                            color: @colorGray !important;
					                            background-color: #fff !important;
			                            	}
	                            		}
		                            	>ul.dropdown-menu {
			                            	border: 2px solid @colorGray !important;	
			                            	background-color: #ffffff !important;	
			                            	a.dropdown-item {
			                            		color: #000 !important;
			                            		&:hover,
			                            		&:focus {
					                            	color: @colorGray !important;
			                            		}
			                        		}
			                        		li.dropdown-submenu {
			                        			>a.nav-link {
				                            		color: #000 !important;
				                            		.transition();
				                            		>span.icon-dropdown-toggle {
													    margin-right: -20px;
													    background-color: #fff;
													    border: 1px solid;
													    border-radius: 0 5px 5px 0;
				                            			float: right;	
				                            			opacity: 0.2;
				                            			.transition();
				                            		}
				                            		&:hover {
				                            			color: @colorGray !important;
				                            			>span.icon-dropdown-toggle {
				                            				opacity:  1;
				                            			}
				                            		}
				                        		}
			                        			div.dropdown-menu {
												    border: 2px solid @colorGray;	
				                            		a {
				                            			color: #000; 	
				                            		}
				                        		}
			                        		}
		                            	}
		                            }
	                            	>a.dropdown-item:hover {
	                            		color: @colorGray !important;
	                            		background-color: #ffffff;
	                            	}
                            	}	
                            }
	                    }
	                }
	            }
        	}
        }
    }
    >section.main-section {
		background: #d8d8d8;
	    padding-bottom: 50px;
		box-shadow: inset 0px 100px 100px 50px #ffffff;
        >.carousel {
		    position: relative;
		    z-index: 0;
        	box-shadow: 0px 100px 100px 50px #ffffff;
        	height: 450px;
        	.transition() ;
            .carousel-indicators {
                display: none;   
            }
            .carousel-inner {
            	height: 450px;
            	>.carousel-item {
            		height: 450px;
            		>img {
					    width: 100%;
					    height: 450px;
            		}
		            >.carousel-caption {
					    left: 0;
					    right: 0;
					    bottom: 0;
					    text-overflow: ellipsis;
					    white-space: nowrap;
					    overflow: hidden;
					    color:#000;
					    margin-right: auto;
					    margin-left: auto;
					    padding-left: 15px;
					    padding-right: 15px;
					    right: 50px;
					    opacity: 0;
					    font-size: 60px;
					    font-family: @fontFamily;
					    .transition(1s,ease-out) ;
					    >h5 {
						    color: #000;
						    font-size: 4rem;
					    	text-shadow: 1px 1px 1px #fff, -1px -1px 1px #fff, -1px 1px 1px #fff, 1px -1px 1px #fff, 1px 0px 1px #fff, 0px 1px 1px #fff;
					    	.transition(1s,ease-out) ;
					    }
					    >p {
						    color: #fff;
						    font-size: 2.5rem;
					    	text-shadow: 1px 1px 1px #000, -1px -1px 1px #000, -1px 1px 1px #000, 1px -1px 1px #000, 1px 0px 1px #000, 0px 1px 1px #000;
					    	.transition(1s,ease-out) ;
					    }
		            }
            		&.active {
            			>.carousel-caption {
            				right: 0px;
            				opacity: 1;
            				font-size: 30px;
            				>b {
							    font-size: 45px;
            				}
            			}
            		}
            	}
            }
	
            .carousel-control {
        		>.glyphicon {
				    background: @colorPrimary;
				    padding: 40px 25px;
				    width: initial;
				    height: initial;
				    color: #fff;
        		}
            	&.left >.glyphicon {
					left: 10px;
					border-radius: 0px 50px 50px 0;
            	}
            	&.right >.glyphicon {
					right: 10px;
					border-radius: 50px 0 0 50px;
            	}
            }
        }
        >.section-center {
		    position: relative;
		    z-index: 1;
            .breadcrumb {
				background: @colorPrimary;
				box-shadow: 0px 5px 5px -5px #000000;
				border-radius: 5px;
			    margin: 15px 0 25px 0;
				>li {
					font-size: 16px;
					font-family: @fontFamily;
					&.active {
						color: #243238;
					}
					>a {
						font-size: 16px;
						font-family: @fontFamily;
						color: @colorSecondary;
					}
				}
				> li + li:before {
				    color: #243238;
				}
            }
            .block-page {
            	a {
            		color: @colorSecondary;	
            		&:hover,
            		&:focus {
            			color: @colorSecondary;	
            			text-decoration: underline;
            		}
            	}
	            .page-header {
				    margin: 0px 0 10px 0;
				    border-bottom: 1px solid @colorPrimary;
				    padding: 0 0 5px 0;
				    border-radius: 0 0 25px 0;
	                > h1,> h2,> h3,> h4,> h5 {
	                    margin: 0;
	                    padding: 0;
	                    text-transform: uppercase;
	                    > .fa {
	                        color: @colorPrimary;
	                    }  
	                }
	            }
	            .panel {
	            	border-color: @colorSecondary;
	            	>.panel-heading {
	            		background-color: @colorPrimary;
	            		>.panel-title {
	            			font-size: 18px; 
							color: #000;
							text-transform: uppercase;
	            		}
	            	}
	            	>.panel-body {
	            		
	            	}
	            	>.list-group {
	            		>.list-group-item {
	            			border-color: @colorSecondary;
	            		}
	            	}
	            }
            	ul.nav-tabs {
            		border-bottom: 1px solid @colorPrimary;
            		>li {
            			border-radius: 5px 5px 0 0;
            			>a {
            				color: #555;
            				border: none;
            				margin-right: 0px;
            			}
            			&.active {
            				border: 1px solid @colorPrimary;
            				border-bottom-color: #fff !important;
	            			>a {
	            				color: @colorPrimary !important;
	            				pointer-events: none;
	            			}	
            			}
            		}
            	}
            	div.tab-content {
					background: #fff;
					border-radius: 0 0 5px 5px;
					padding: 15px;
					border: 1px solid @colorPrimary;
					border-top: none;	
            	}
                .carousel {
                    height: 390px;
                    min-height: 390px;
                    >.carousel-indicators {
                        bottom: -20px;
                        >li {
                            border: 1px solid #ccc;
                            background-color: #fff;
                            &.active {
                                background-color: #ddd;
                                border: 2px solid rgb(128, 128, 128);
                            }
                        }
                    }
                    >.carousel-inner {
                        height: 390px;
                        min-height: 390px;
                        > a {
                            height: 390px;
                            min-height: 390px;
                            > .picture {
                                width: 100%;
                                height: 250px;
                                min-height: 250px;
                                background-size: contain !important;
                                background-position: center !important;
                                background-repeat: no-repeat !important;
                            }
                            >.carousel-caption {
                                position: static;
                                height: 140px;
                                min-height: 140px;
                                padding: 10px;
                                text-align: left;
                                text-shadow: none;
                                color: #000;
                                >.caption-date {
                                    float: left;
                                    border: 1px solid #ccc;
                                    width: 54px;
                                    text-align: center;
                                    margin-right: 10px;
                                    background: #ffffff;
                                    >.date-day {
                                        font-size: x-large;
                                        font-weight: bold; 
                                        border-bottom: 1px solid #ccc;
                                    }
                                    >.data-month {
                                        color: #34495c;
                                        font-size: small;
                                        text-transform: uppercase;
                                        background: #dfe0e1;
                                    }
                                    >.date-days {
                                        font-size: large;
                                        font-weight: bold; 
                                        border-bottom: 1px solid #ccc;
                                    }
                                    >.data-months {
                                        color: #34495c;
                                        font-size: x-small;
                                        text-transform: uppercase;
                                        background: #dfe0e1;
                                    }
                                }
                                >.caption-author {
                                    font-size: small;
                                    color: #002850;  
                                }
                                >.caption-title {
                                	color: #34495c;
                                    font-size: medium;
                                    font-weight: bold;
                                    line-height: 18px;
                                    margin-bottom: 10px;
                                    padding-bottom: 10px;
                                    border-bottom: 1px solid #EEE;
                                    overflow: hidden;
                                    height: 37px;
                                }
                                >.caption-text {
                                    font-size: small;
                                    text-align: justify;
                                    overflow: hidden;
                                    height: 50px;
                                }
                            }
                        }
                    }
                    >.carousel-control {
                    	bottom: 140px;
                    }
                }
            }
        }
		&.color-blue {
	        >.container {
	        	.breadcrumb {
	        		background: @colorBlue;	
	            	>li {
						&.active {
							color: #d8d8d8;
						}
						>a {
							color: #fff;
						}
					}
					> li + li:before {
					    color: #d8d8d8;
					}
	        	}
	        	.block-page {
		            .page-header {
					    border-bottom: 1px solid @colorBlue;
		                > h1,> h2,> h3,> h4,> h5 {
		                    > .fa {
		                        color: @colorBlue;
		                    }  
		                }
		            }
		            .panel {
		            	border-color: @colorBlue;
		            	>.panel-heading {
		            		background-color: @colorBlue;
		            		>.panel-title {
								color: #fff;
		            		}
		            	}
		            	>.panel-body {
		            		
		            	}
		            	>.list-group {
		            		>.list-group-item {
		            			border-color: @colorBlue;
		            		}
		            	}
		            }
	        	}
	        }
		}
        &.color-purple {
	        >.container {
	        	.breadcrumb {
	        		background: @colorPurple;	
	            	>li {
						&.active {
							color: #d8d8d8;
						}
						>a {
							color: #fff;
						}
					}
					> li + li:before {
					    color: #d8d8d8;
					}
	        	}
	        	.block-page {
		            .page-header {
					    border-bottom: 1px solid @colorPurple;
		                > h1,> h2,> h3,> h4,> h5 {
		                    > .fa {
		                        color: @colorPurple;
		                    }  
		                }
		            }
		            .panel {
		            	border-color: @colorPurple;
		            	>.panel-heading {
		            		background-color: @colorPurple;
		            		>.panel-title {
								color: #fff;
		            		}
		            	}
		            	>.panel-body {
		            		
		            	}
		            	>.list-group {
		            		>.list-group-item {
		            			border-color: @colorPurple;
		            		}
		            	}
		            }
	        	}
	        }
		}
        &.color-green {
	        >.container {
	        	.breadcrumb {
	        		background: @colorGreen;	
	            	>li {
						&.active {
							color: #d8d8d8;
						}
						>a {
							color: #fff;
						}
					}
					> li + li:before {
					    color: #d8d8d8;
					}
	        	}
	        	.block-page {
		            .page-header {
					    border-bottom: 1px solid @colorGreen;
		                > h1,> h2,> h3,> h4,> h5 {
		                    > .fa {
		                        color: @colorGreen;
		                    }  
		                }
		            }
		            .panel {
		            	border-color: @colorGreen;
		            	>.panel-heading {
		            		background-color: @colorGreen;
		            		>.panel-title {
								color: #fff;
		            		}
		            	}
		            	>.panel-body {
		            		
		            	}
		            	>.list-group {
		            		>.list-group-item {
		            			border-color: @colorGreen;
		            		}
		            	}
		            }
 
	        	}
	        }
		}
        &.color-orange {
	        >.container {
	        	.breadcrumb {
	        		background: @colorOrange;	
	            	>li {
						&.active {
							color: #d8d8d8;
						}
						>a {
							color: #fff;
						}
					}
					> li + li:before {
					    color: #d8d8d8;
					}
	        	}
	        	.block-page {
		            .page-header {
					    border-bottom: 1px solid @colorOrange;
		                > h1,> h2,> h3,> h4,> h5 {
		                    > .fa {
		                        color: @colorOrange;
		                    }  
		                }
		            }
		            .panel {
		            	border-color: @colorOrange;
		            	>.panel-heading {
		            		background-color: @colorOrange;
		            		>.panel-title {
								color: #fff;
		            		}
		            	}
		            	>.panel-body {
		            		
		            	}
		            	>.list-group {
		            		>.list-group-item {
		            			border-color: @colorOrange;
		            		}
		            	}
		            }
	            	ul.nav-tabs {
	            		border-bottom-color: @colorOrange;
	            		>li {
	            			&.active {
	            				border-color: @colorOrange;
		            			>a {
		            				color: @colorOrange !important;
		            			}	
	            			}
	            		}
	            	}
	            	div.tab-content {
						border-color: @colorOrange;
	            	}
	        	}
	        }
		}
        &.color-lightblue {
	        >.container {
	        	.breadcrumb {
	        		background: @colorLightblue;	
	            	>li {
						&.active {
							color: #d8d8d8;
						}
						>a {
							color: #fff;
						}
					}
					> li + li:before {
					    color: #d8d8d8;
					}
	        	}
	        	.block-page {
		            .page-header {
					    border-bottom: 1px solid @colorLightblue;
		                > h1,> h2,> h3,> h4,> h5 {
		                    > .fa {
		                        color: @colorLightblue;
		                    }  
		                }
		            }
		            .panel {
		            	border-color: @colorLightblue;
		            	>.panel-heading {
		            		background-color: @colorLightblue;
		            		>.panel-title {
								color: #fff;
		            		}
		            	}
		            	>.panel-body {
		            		
		            	}
		            	>.list-group {
		            		>.list-group-item {
		            			border-color: @colorLightblue;
		            		}
		            	}
		            }
	        	}
	        }
		}
        &.color-violet {
	        >.container {
	        	.breadcrumb {
	        		background: @colorViolet;	
	            	>li {
						&.active {
							color: #d8d8d8;
						}
						>a {
							color: #fff;
						}
					}
					> li + li:before {
					    color: #d8d8d8;
					}
	        	}
	        	.block-page {
		            .page-header {
					    border-bottom: 1px solid @colorViolet;
		                > h1,> h2,> h3,> h4,> h5 {
		                    > .fa {
		                        color: @colorViolet;
		                    }  
		                }
		            }
		            .panel {
		            	border-color: @colorViolet;
		            	>.panel-heading {
		            		background-color: @colorViolet;
		            		>.panel-title {
								color: #fff;
		            		}
		            	}
		            	>.panel-body {
		            		
		            	}
		            	>.list-group {
		            		>.list-group-item {
		            			border-color: @colorViolet;
		            		}
		            	}
		            }
	        	}
	        }
		}
        &.color-gray {
	        >.container {
	        	.breadcrumb {
	        		background: @colorGray;	
	            	>li {
						&.active {
							color: #d8d8d8;
						}
						>a {
							color: #fff;
						}
					}
					> li + li:before {
					    color: #d8d8d8;
					}
	        	}
	        	.block-page {
		            .page-header {
					    border-bottom: 1px solid @colorGray;
		                > h1,> h2,> h3,> h4,> h5 {
		                    > .fa {
		                        color: @colorGray;
		                    }  
		                }
		            }
		            .panel {
		            	border-color: @colorGray;
		            	>.panel-heading {
		            		background-color: @colorGray;
		            		>.panel-title {
								color: #fff;
		            		}
		            	}
		            	>.panel-body {
		            		
		            	}
		            	>.list-group {
		            		>.list-group-item {
		            			border-color: @colorGray;
		            		}
		            	}
		            }
	        	}
	        }
		}	
    }
    >footer.main-footer {
		background: url("@{host}/public/img/footer-background.jpg") repeat-x center bottom 40px #ffd502;
		margin-bottom: 0 !important;
        >section.footer-section {
        	>.section-header {
        		padding: 2rem 0;
	            >.container {
	            	>div.row {
	            		>div {
			                >.page-header {
								margin: 10px 10px 0 10px;
								border: none;
								padding-bottom: 1rem;
			                    >h3 {
			                        color: @colorSecondary;
								    padding: 10px;
								    background: #fff;
								    border-radius: 5px;
								    text-align: center;
								    display: inline-block;
								    margin: 0;
								    min-width: 75%;
								    text-align: left;
			                    }
			                    >p {
								    margin: 10px 0;
								    color: #000;
								    font-size: 20px;
			                    }
			                }
			                >p {
			                	margin: 0 10px 10px 10px;
			                	font-family: @fontFamily;
			                	font-size: 20px;
			                }
			                >ul {
								list-style: none;
							    padding: 0;
							    margin: 0 10px 40px 10px;
			                    >li {
									color:#000;
								    line-height: 28px;
								    text-align: left;
				                	font-family: @fontFamily;
				                	font-size: 20px;
			                        >a {
			                            color: #000;
					                	font-family: @fontFamily;
					                	font-size: 20px;
			                            text-shadow: none;
			                            display: block;
			                            .transition() ;
			                            &:hover,
			                            &:focus {
			                                color: #fff;
			                                text-shadow: 1px 1px 1px #e73125, -1px -1px 1px #e73125, -1px 1px 1px #e73125, 1px -1px 1px #e73125, 1px 0px 1px #e73125, 0px 1px 1px #e73125;
			                                text-decoration: none;
			                            }
			                        }
			                    }
			                }
			                >form {
			                	margin: 0 10px 10px 10px;
			                	>.form-group {
			                		margin-bottom: 5px;
			                		>.form-control {
			                			border: 1px solid @colorSecondary;	
			                			box-shadow: inset 0 1px 1px @colorSecondary;
			                			background-color: #fcfcfc;
			                			.transition() ;
			                			&:hover,
			                			&:focus {
			                				border-color: @colorSecondary;
			                				box-shadow: inset 0 1px 1px rgb(255, 204, 0), 0 1px 1px @colorSecondary;
			                				background-color: #fff;
			                			}
			                		}
			                	}
			                	.btn.btn-default {
									color: @colorSecondary;
									background-color: @colorPrimary;
									border: 1px solid @colorSecondary;
									font-family: @fontFamily;
									font-size: 20px;
									.transition() ;
		                			&:hover,
		                			&:focus {
										color: @colorPrimary;
										background-color: @colorSecondary;
										border: 1px solid @colorSecondary;
		                			}
			                	}
			                }
	            		}
	            	}
	
	            }
        	}
        	>.section-footer {
                background: #fff;
                background-image: -webkit-linear-gradient(top,#fff 0%,#cbcbcb 100%);
                background-image: linear-gradient(to bottom,#fff 0%,#cbcbcb 100%);
                background-repeat: repeat-x;
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffcbcbcb', GradientType=0);
            }
        }
    }
}

@media (min-width: 768px) {
	.container {
	    width: 100%;
	}
	main.main >section.main-section >.carousel {
		margin-top: 0;
		.carousel-caption {
		    font-size: 16px;
			width: inherit;
			text-align: center;
			line-height: 20px;
			>b {
			    font-size: 24px;
			}
		}
	}
}
@media (min-width: 992px) {
	.container {
		width: 100%;
	}
	main.main >section.main-section >.carousel {
		margin-top: 0;
		.carousel-caption {
		    font-size: 24px;
			width: inherit;
			text-align: center;
			line-height: 28px;
			>b {
			    font-size: 36px;
			}
		}
	}
}
@media (min-width: 1200px) {
	.container {
    	width: 1170px;
	}
	main.main >section.main-section >.carousel {
		margin-top: 0px;
		.carousel-caption {
			font-size: 30px;
			width: 1170px;
			text-align: right;
			line-height: 34px;
			>b {
			    font-size: 45px;	
			}
		}
	}
}